.paralax-container {
    h1, h5, p {
        color: #ffffff;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    h1 {
        margin: 0;
        font-size: 35px;
        line-height: 1.3;
    }
    h5 {
        font-size: 15px;
        margin-bottom: 10px;        
    }
    .button {
        display: inline-block;
        margin-top: 40px;
    }
    
}