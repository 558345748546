
.smallstage-row {
    background-color: #888888;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    .smallstage-container__text {
        color: #ffffff;
        display: table-cell;
        vertical-align: middle;
    }
    h1 {
        font-size: 45px;
        text-transform: uppercase;
        @media screen and (max-width: 900px) {
            font-size: 5vw;
        }
    }
    .smallstage-container {
        padding-top: 7%;
        padding-bottom: 7%;
    }
    &.text-only {
        .smallstage-container {
            padding-top: 3%;
            padding-bottom: 3%;
        }
    }
}

.newslist-container {
    padding-top: 50px;
    
    h3 {
        margin-top: 0;
    }
    @media screen and (max-width: $screen-xs-max) {
        .textcol {
            margin-top: 20px;
        }
    }
}

.examples-container {
    h2 {
        font-family: montserrat;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 1.5px;
        margin-bottom: 25px;
        text-align: center;
    }    
}

.examples-row + .examples-row {
    margin-top: 20px;
}

.boxes-container {
    padding-top: 40px;
    padding-bottom: 40px;
    h4 {
        font-family: montserrat;
        font-weight: normal;
        color: #333333;
        font-size: 18px;
        margin-bottom: 12px;
        margin-top: 18px;
    }
    a {
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.3px;
        font-size: 14px;        
    }
    
}

.paralax-container {
    padding-top: 120px;
    padding-bottom: 120px;
}

.text-container {
    padding-top: 30px;
    padding-bottom: 30px;
    h1, h2, h3, h4 {
        font-family: montserrat;
        font-weight: 400;
        font-style: normal;
        line-height: 1.3;
        color: #333333;
    }
    h1 {
        font-size: 30px;
        text-transform: uppercase;
    }
    h2 {

    }
    h3 {

    }
    h4 {
        font-size: 18px;
        margin-bottom: 12px;
        line-height: 1.7;        
    }
    .button {
        display: inline-block;
        letter-spacing: 1px;
        text-transform: uppercase;
        line-height: 1;
        text-align: center;
        padding: 11px 18px 13px 18px;
        margin: 30px 0 0 0;
        font-weight: 500;
        font-size: 13px;
        font-family: "montserrat", Helvetica, Arial, sans-serif;
        border-radius: 2px;
        box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.1) inset; 
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        color: #888888;
        &:hover {
            opacity: 0.9 !important;
            text-decoration: none !important;            
        }
    }
}