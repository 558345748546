.header {
    z-index: 100;
//    background: rgba(0,0,0,.1);
    background: #ffffff;
    padding: 20px 0 0 0;
    width: 100%;
}

.phone-header {
//    color: $color_dark_blue;
//    font-size: 22px;
    font-weight: bold;
    margin-left: 6px;
    white-space: nowrap;
}

.logo {
    a {
        display: block;
    }
    img {
        width: 100%;
        height: auto;
    }
    width: 70%;
    max-width: 300px;
}

.logo-row {
    padding-bottom: 20px;
}

.phoneblock {
    margin-top: 10px;
    @media screen and (max-width: 700px) {
        width: 100%;
    }    
}

/*
.header {
  padding: 20px 0 0 0;
  text-align: center;
}

.header-brand {
  width: 60px;
  height: 60px;
}

.header-nav > li {
  display: inline-block;
}

.header-nav > li + li {
  margin-left: 20px;
}

.header-nav > li > a {
  padding-right: 0;
  padding-left: 0;
  font-size: 16px;
  font-weight: bold;
  color: #fff;  // IE8 proofing
  color: rgba(255, 255, 255, 0.8);
  border-bottom: 2px solid transparent;
}

.header-nav > li > a:hover,
.header-nav > li > a:focus {
  background-color: transparent;
  border-bottom-color: #a9a9a9;
  border-bottom-color: rgba(255, 255, 255, .25);
}

.header-nav > .active > a,
.header-nav > .active > a:hover,
.header-nav > .active > a:focus {
  color: #fff;
  border-bottom-color: #fff;
}



@media (min-width: $screen-sm-min) {

  .header {
    position: fixed;
    top: 8vh;
    left: 0;
    right: 0;
    z-index: 100;
  }
  .header-brand {
    float: left;
    width: 100px;
    height: 100px;
  }
  .header-nav {
    float: right;
  }
}

*/