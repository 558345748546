// SVG icons

.icon {
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  fill: #fff;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  transform: translateY(-0.7px); // slightly improve positioning
}

