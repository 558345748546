body {
    .swiper-container {
        height: 0;
        padding-bottom: 48%;
        width: 100%;
    }
    .swiper-wrapper {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
    .swiper-slide {
        background-position: center;
        background-size: cover;
        display: table;
    }
    .swiper-pagination-bullet {
        border: 2px solid #555555;
        width: 12px;
        height: 12px;
        opacity: 0.8;
    }
    .swiper-pagination-bullet-active {
        border-color: $color_dark_blue;
    }
    .swiper-pagination {
        @media screen and (max-width: 600px) {
            display: none;
        }        
    }
    
    .swiper-overlay {
        display: table-cell;
        padding: 20px 10%;
        vertical-align: middle;
        .stage-line-1 {
            font-family: montserrat, Helvetica, sans-serif;
            font-size: 40px;
            font-weight: 700;
            letter-spacing: 2px;
            line-height: 1.1;
            text-transform: uppercase;
            @media screen and (max-width: 1000px) {
                font-size: 4vw;
                line-height: 1;
            }
        }
        .stage-line-2 {
            font-size: 16px;
            margin-top: 10px;
            @media screen and (max-width: 700px) {
                display: none;
            }
        }
        &.scheme_dark {
            color: $color_dark_blue;
        }
        &.scheme_bright {
            color: #ffffff;
        }
        &.scheme_big {
            text-align: center;
            .stage-line-1 {
                font-size: 70px;
                letter-spacing: 2px;
                line-height: 70px;
                @media screen and (max-width: 1000px) {
                    font-size: 7vw;
                    line-height: 1;
                }
            }
            .stage-line-2 {
                color: #ffffff;
                font-size: 20px;
                font-weight: bold;
                letter-spacing: 2px;
                line-height: 20px;
                text-shadow: 0px 0px 20px rgba(0, 0, 0, 1);
                text-transform: uppercase;
                @media screen and (max-width: 700px) {
                    display: none;
                }
            }
            .button {
                margin-right: 0;
            }
        }
        .button {
            background: none;
            border: none;
            display: inline-block;
            margin-top: 25px;
            padding: 0;
            text-transform: uppercase;
            margin-right: 30px;
            a {
                background: $color_dark_blue;
                border: 2px solid $color_dark_blue;
                border-radius: 3px;
                letter-spacing: 1px;
                color: #ffffff;
                display: inline-block;
                padding: 8px 20px;
                &:hover {
                    text-decoration: none;
                }
            }
            &.button_2 a {
                background: none;
                border-color: #ffffff;
                &:hover {
                    background-color: #ffffff;
                    color: #555555;
                }
            }
            @media screen and (max-width: 550px) {
                margin-top: 4%;
                a {
                    padding: 6px 15px;
                    font-size: 16px;
                    letter-spacing: 0;
                }
            }
        }
        @media screen and (max-width: 450px) {
            .button_2 {
                display: none;
            }
        }
        
        
        
        
    }
}
