/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 27.03.2017, 12:57:38
    Author     : wolfgang
*/

.yform {
    label {
        font-weight: normal;
    }
}