/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 19.03.2017, 17:39:26
    Author     : wolfgang
*/

html, body {
    position: relative;
    height: 100%;
    font-family: pt-sans, Helvetica, sans-serif;
}

body {
    overflow-x: hidden;
    font-family: "pt-sans";
    line-height: 1.7;
    color: #777777;
    font-size: 18px;
}

*, *:before, *:after {
    box-sizing: border-box;
}

.container {
    max-width: 1280px;
    width: 100%;
}



.bg_color_yellow {
    background: $color_yellow;
}

.bg_color_blue {
    background: $color_blue;
}

.bg_color_gray {
    background: #888888;    
}

.bg_bright_gray {
    background: #f8f9f9;
}

.bg_color_dark_blue, .bg_dark_blue {
    background: $color_dark_blue;
}

.bar-row {
    font-family: montserrat, Helvetica, sans-serif;
    font-size: 20px;
    color: #ffffff;
    margin-top: -1px;
    padding: 23px 0 0 0;
    span {
        margin-top: 5px;
        margin-bottom: 20px;
    }
    .fl-right {
        margin-bottom: 20px;
    }
}

.button {
    border: 2px solid #ffffff;
    border-radius: 3px;    
    color: #ffffff;
    font-size: 13px;
    letter-spacing: 1px;
    padding: 10px 20px;
    text-transform: uppercase;
    white-space: nowrap;
    &:hover {
        background: #ffffff;
        color: #555555;
        text-decoration: none;
    }
}

.button.blue {
    border: 2px solid $color_dark_blue;
    color: $color_dark_blue;
    &:hover {
        background: $color_dark_blue;
        color: #ffffff;
        text-decoration: none;
    }
}

.text-wrapper {
    max-width: 900px;
}