/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 20.03.2017, 17:39:05
    Author     : wolfgang
*/

.fl-right {
    float: right;
}

.fl-left {
    float: left;
}

.img-responsive {
    width: 100%;
}

a {
    color: $color_dark_blue;
    &:hover {
        color: $color_dark_blue;
    }
}

.mt-10 {
    margin-top: 10px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-30 {
    margin-top: 30px;
}
.mt-40 {
    margin-top: 40px;
}

.mb-10 {
    margin-bottom: 10px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mb-40 {
    margin-bottom: 40px;
}