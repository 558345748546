// $body-bg: #444;
// $text-color: rgba(255, 255, 255, 0.9);
// $link-color: #fff;

$grid-gutter-width: 40px !default;

// $container-large-desktop:      (1140px + $grid-gutter-width) !default;


$color_blue: #1dbbea;
$color_dark_blue: #066fb0;

$color_yellow: #fab702;


/*

$screen-xs:                  480px !default;
$screen-sm:                  768px !default;
$screen-md:                  992px !default;
$screen-lg:                  1200px !default;

*/