@font-face {
    font-family: 'pt-sans';
    src:  url('/assets/fonts/PT_Sans-Web-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400; // normal
}

@font-face {
    font-family: 'montserrat';
    src:  url('/assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'montserrat';
    src:  url('/assets/fonts/Montserrat-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400; // normal
}

@font-face {
    font-family: 'FontAwesome';
    src:  url('/assets/fonts/fontawesome-webfont.woff') format('woff');
    font-style: normal;
    font-weight: 400; // normal
}




/*
Montserrat-Black.ttf
Montserrat-BlackItalic.ttf
Montserrat-Bold.ttf
Montserrat-BoldItalic.ttf
Montserrat-ExtraBold.ttf
Montserrat-ExtraBoldItalic.ttf
Montserrat-ExtraLight.ttf
Montserrat-ExtraLightItalic.ttf
Montserrat-Italic.ttf
Montserrat-Light.ttf
Montserrat-LightItalic.ttf
Montserrat-Medium.ttf
Montserrat-MediumItalic.ttf
Montserrat-Regular.ttf
Montserrat-SemiBold.ttf
Montserrat-SemiBoldItalic.ttf
Montserrat-Thin.ttf
Montserrat-ThinItalic.ttf
PT_Sans-Web-Bold.ttf
PT_Sans-Web-BoldItalic.ttf
PT_Sans-Web-Italic.ttf
PT_Sans-Web-Regular.ttf
*/