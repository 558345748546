.mainnav {
    ul {
        list-style: none;
        margin-bottom: 0;
        padding: 0;
        float: right;
        margin-left: -10px;
        li {
            float: left;
            a {
                color: #ffffff;
                display: block;
                padding: 2px 10px;
                &.active, &.current {
                    font-weight: bold;
//                    color: $color_dark_blue;
                }
            }
            &:last-child a {
                padding-right: 0;
            }
        }
    }
}

#menuswitch {
    position: absolute;
    opacity: 0;
    left: -100%;
}

.mainnav-row {
    padding-bottom: 6px;
    padding-top: 6px;
}

@media screen and (max-width: 750px) {
    .mainnav-row {
//        background: rgba(255,255,255,1);
        background: $color_dark_blue;
        display: none;
        left: 0;
        padding-bottom: 0;
        padding-top: 0;
        position: absolute;
        z-index: 10000;
        .mainnav {
            ul {
                border-top: 1px solid #aaa;
                margin-left: 0;
                li {
                    a {
                        padding-top: 6px;
                        padding-bottom: 6px;
                    }
                    border-bottom: 1px solid #aaa;
                    width: 100%;
                }
            }
       }
    }
    #menuswitch:checked + .mainnav-row {
        display: block;
    }
    
}


.fa.menu-switcher {
    cursor: pointer;
    display: none;
    font-size: 36px;
    margin-left: 10px;
    margin-right: -10px;
    padding: 10px;
    @media screen and (max-width: 750px) {
        display: block;
    }
}